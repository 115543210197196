import * as React from "react"
import { Hero } from "../components/hero"
import { StaticImage } from "gatsby-plugin-image"
import { BigLinks } from "../components/big-links"
// import { graphql } from "gatsby"
// import { RenderMDX } from "../components/render-mdx"
import { aboutStyles } from "./about.module.css"

interface AboutProps {
  pageContext: any
  data: any
}

export default function AboutPage({ pageContext, data }: AboutProps) {
  const { i18n } = pageContext

  return (
    <>
      <Hero title={i18n.about.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_split.jpg"
          objectPosition="70% 50%"
          alt=""
          quality={95}
        />
      </Hero>
      <div className={`${aboutStyles} text-lg my-12 out-wrapper`}>
        <div className="my-1 mr-5 text-center sm:float-left">
          <a href="http://juliendesforges.com">
            <StaticImage
              className="rounded-sm drop-shadow-lg"
              src="../assets/images/about/fondateur.jpg"
              alt={i18n.about.founderAlt}
            />
          </a>
        </div>
        <p className="text-xl font-bold">
          {i18n.about.p1}{" "}
          <a className="link-underline" href="http://juliendesforges.com">
            Julien Desforges
          </a>{" "}
          {i18n.about.p2}
        </p>
        <p>
          {i18n.about.p3}{" "}
          <a className="link-underline" href="http://www.pierrecarrillo.com/">
            Pierre Carrillo
          </a>{" "}
          {i18n.about.p4}{" "}
          <a
            className="link-underline"
            href="http://highliningcalifornia.com/FRlowres.html"
          >
            Highlining California
          </a>{" "}
          {i18n.about.p5}{" "}
          <a
            className="link-underline"
            href="https://www.facebook.com/dhanaebeaulieu"
          >
            Dhanaé Audet-Beaulieu
          </a>
          {i18n.about.p6}
        </p>
        <div className="clear-both" />
        <div className="my-1 ml-5 text-center sm:float-right">
          <a href="https://www.flickr.com/photos/slacklinemontreal/sets/72157631181977570/">
            <StaticImage
              className="rounded-sm drop-shadow-lg"
              src="../assets/images/about/yosemite.jpg"
              alt={i18n.about.yosemiteAlt}
            />
          </a>
        </div>
        <p>{i18n.about.p7}</p>
        <p>{i18n.about.p8}</p>
        <p>{i18n.about.p9}</p>
        <p>{i18n.about.p10}</p>
        <div className="clear-both" />
        <div className="mx-auto mt-8 text-center">
          <a href="https://www.flickr.com/photos/slacklinemontreal/sets/72157642855123575/">
            <StaticImage
              className="rounded-sm drop-shadow-lg"
              src="../assets/images/about/service_zones.jpg"
              alt={i18n.about.slackZoneAlt}
            />
          </a>
        </div>
      </div>
      <BigLinks i18n={i18n} />
    </>
  )
}

// export const query = graphql`
//   query AboutQuery($lang: String) {
//     allMdx(
//       filter: { frontmatter: { lang: { eq: $lang }, id: { eq: "about" } } }
//     ) {
//       nodes {
//         frontmatter {
//           id
//           title
//         }
//         body
//       }
//     }
//   }
// `
